import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PostBlurb from '../components/postBlurb'
import Navigation from '../components/navigation'

import '../styles/layout.css'

const Tags = ({
    data,
    pageContext: { nextPagePath, previousPagePath, tag },
  }) => {
    const {
      allMdx: { edges: posts },
  } = data

  return (
    <>
      <SEO />
      <Layout>
        <div className="infoBanner">
          Posts with tag: <span>#{tag}</span>
        </div>

        {posts.map(({ node }) => {
          const {
            id,
            frontmatter: {
              title,
              date,
              path,
              coverImage,
              blurb,
              tags,
            },
          } = node

          return (
            <PostBlurb
              key={id}
              title={title}
              date={date}
              path={path}
              coverImage={coverImage}
              blurb={blurb}
              tags={tags}
            />
          )
        })}

        <Navigation
          previousPath={previousPagePath}
          previousLabel="Newer posts"
          nextPath={nextPagePath}
          nextLabel="Older posts"
        />
      </Layout>
    </>
  )
}

Tags.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
    previousPagePath: PropTypes.string,
    tag: PropTypes.string,
  }),
}


export const query= graphql`
query($limit: Int, $skip: Int, $tag: String) {
  allMdx(
    filter: { frontmatter: { tags: { in: [$tag] } } }
    sort: { fields: [frontmatter___date], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          date
          path
          blurb
          tags
          coverImage {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                height: 200
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: INSIDE }
              )
            }
          }
        }
      }
    }
  }
}`

export default Tags